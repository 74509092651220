@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

html,
body {
  overflow-x: hidden;
}

* {
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-link div {
  background-color: #6bbf79;
  color: #fff;
}

::-webkit-scrollbar {
  width: 0;
}

main::-webkit-scrollbar {
  width: 13px;
}

main::-webkit-scrollbar-track {
  background: rgb(201, 197, 197);
}

main::-webkit-scrollbar-thumb {
  background: gray;
}

.table-holder {
  overflow: hidden;
  max-width: 100%;
}

.table-holder table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
  width: 100%;
}

.table-holder td {
  overflow: hidden;
}

.table-holder thead tr th {
  white-space: nowrap;
}

.table-holder thead tr:first-child th:first-child {
  border-bottom-left-radius: 12px;
}
.table-holder thead tr:last-child th:last-child {
  border-bottom-right-radius: 12px;
}
.table-holder tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.table-holder tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.searchBar .dropdown {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 220px;
  width: fit-content;
  height: 44px;
  background: #f2f2f2;
  word-break: break-all;
  overflow-wrap: break-word;
}

.searchBar .dropdown li {
  position: relative;
  width: 100%;
}

.searchBar .dropdown li label {
  color: #4a4a4a;
  text-decoration: none;
  display: block;
  padding: 10px;
  width: 220px;
  word-break: break-all;
}

.searchBar .dropdown li ul {
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  line-height: normal;
  background: #f2f2f2;
}

.searchBar .dropdown li ul li label {
  text-align: left;
  color: #4a4a4a;
  font-size: 14px;
  padding: 10px;
  display: block;
  white-space: nowrap;
}

.searchBar .dropdown li ul li label:hover {
  background-color: #bfefa1;
  cursor: pointer;
}

.searchBar .dropdown li ul li ul {
  left: 100%;
  top: 0;
  z-index: 50;
}

.searchBar ul li:hover > label {
  background-color: #bfefa1;
  cursor: pointer;
}

.searchBar ul li:hover > ul {
  display: block;
  width: 100%;
  word-break: break-all;
}

.searchBar {
  background: #39423b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 17px 20px 10px 20px;
  margin-bottom: -10px;
}

.status {
  padding: 4px 8px;
  border-radius: 20px;
}

.status-Mirovanje {
  background: #fff7b3;
  color: #7e6300;
  border: #ffe380;
}

.status-Aktivan {
  background: #e3fcef;
  color: #006644;
  border: #abf5d1;
}

.status-Održavanje {
  background: #e3fbfc;
  color: #005a66;
  border: #abe3f5;
}

.date-btn {
  height: 44px;
  width: 261px;
  border: 1px solid #d8d8d8;
  color: #4a4a4a;
}

.rdrDateRangeWrapper {
  position: absolute;
  left: 0;
  top: 45px;
  z-index: 10;
}

textarea {
  border: 1px solid #e2e2e2;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  box-sizing: border-box;
  padding: 10px;
}

.formInput {
  height: 40px;
  padding: 10px;
  border: 1px solid #e2e2e2;
  margin-bottom: 12px;
  border-radius: 4px;
}

.inputError {
  border-color: rgb(204, 63, 63);
}

.exit {
  position: absolute;
  right: 30%;
  top: 45%;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 90px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #75c445;
  color: #fff;
  text-align: center;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: 50%;
  margin-left: -60px;
}

.exit-btn:hover .tooltiptext {
  visibility: visible;
}

.noInputArrows::-webkit-outer-spin-button,
.noInputArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.mRight {
  margin-right: 26px;
}

.divWrap {
  word-break: break-word;
  display: flex;
  align-items: center;
  max-width: 455px;
  height: fit-content;
  overflow-wrap: break-word;
  line-height: 20px;
  overflow: hidden;
}

.my-bg-green {
  background-color: #75c445;
  border-color: #75c445;
}

.active-link p {
  color: #fff;
}

.active-link svg path {
  fill: #fff;
}

table {
  border-style: none;
  border-collapse: collapse;
}

th:not(:first-of-type)::before,
.paymentValueHeader::before {
  content: '|';
  color: rgb(228, 223, 223);
  margin-right: 10px;
}

.paymentValueHeader::before {
  margin-left: -15px;
}

th:first-child::before {
  content: '';
  margin-left: 14px;
}

.type-style {
  font-weight: 600;
  font-size: 12px;
  padding: 3px 4px;
  border-radius: 4px;
}

.type-jelovnik {
  color: #b50707;
  background: #ffd2cc;
}

.type-recept {
  color: #039762;
  background: #d2fff4;
}

.type-biohemija {
  color: #720499;
  background: #dac3ff;
}

.type-anamneza {
  color: #997804;
  background: #ffeeb1;
}

.type-izveštaj {
  color: #027e8e;
  background: #d7fdff;
}

.inputWidth {
  width: 220px;
}

.searchBarIndex {
  z-index: 1;
}

.contact-column {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px;
  height: 48px;
}

.bg-column-lightgray {
  background-color: #f8f8f8;
}

.contact-link {
  margin-left: 12px;
  text-decoration: underline;
  color: #1a4e9c;
}
.contact-textarea {
  background-color: #f8f8f8;
}

.contact-form {
  width: 595px;
}

.submit-feedback-btn {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 16px 30px 16px 28px;
  width: 100%;
  height: 48px;
  background: #75c445;
  border-radius: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.disabledBtn {
  background: #f8f8f8;
  color: #fff;
  pointer-events: none;
}

@media screen and (max-width: 340px) {
  .confirmation-dialogs-btn-holder {
    justify-content: center;
  }

  .confirmation-dialogs-btn-holder button {
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
}

.calendarTemplate {
  max-width: 617px;
}

@media (min-width: 768px) {
  .test {
    width: 350px;
    margin-left: auto;
  }
}

@media (min-width: 1210px) {
  .calendarTemplate {
    margin-top: -95px;
  }
}

.documents-list :not(.activeFolder).file-holder:nth-child(even) {
  background-color: #fff;
}

.contactButtons {
  width: 108px;
  height: 80px;
  border-radius: 4px;
}

.mInline {
  margin-inline: 100%;
}

.tabList {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 247px;
  flex-shrink: 0;
}

.newPatient {
  max-width: 1124px;
  display: flex;
  margin: auto;
  margin-top: 2rem;
}

.switch-btn-holder .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch-btn-holder .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-btn-holder .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-btn-holder .slider:before {
  position: absolute;
  content: '';
  height: 12.5px;
  width: 12.5px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-btn-holder input:checked + .slider {
  background-color: #6cc250;
}

.switch-btn-holder input:focus + .slider {
  box-shadow: 0 0 1px #6cc250;
}

.switch-btn-holder input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.switch-btn-holder .slider.round {
  border-radius: 34px;
}

.switch-btn-holder .slider.round:before {
  border-radius: 50%;
}
